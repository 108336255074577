const sundays = [
  ["May 26", "Ford", "6am - 12pm", "Broc/Justin (14.71)"],
  ["June 2", "*Belleville", "6am - 12pm", "Broc/Justin (14.17)"],
  ["June 9", "Chemung", "6am - 12pm", "Ralph/Rodney (14.80)"],
  ["June 16", "Woodland", "6am - 12pm", "Jimmie (15.50)"],
  ["June 23", "Sand", "6am - 12pm", "Jon (14.47)"],
  ["July 7", "Ford", "6am - 12pm", "Mark/Kevin (11.63)"],
  ["July 14", "Whitmore", "6am - 12pm", "Jimmie (18.79)"],
  ["July 21", "Devils", "6am - 12pm", "Mark/Kevin (13.68)"],
  ["July 28", "Woodland", "6am - 12pm", "Marty/Terry (13.82)"],
  ["Aug 4", "Whitmore", "6am - 12pm", "Mark/Kevin (15.35)"],
  ["Aug 11", "Wamplers", "8am - 2pm", "Jimmie (14.75)"],
  ["Aug 18", "Chemung", "6am - 12pm", "Mark/Kevin (15.25)"],
  ["Aug 25", "Halfmoon", "8am - 2pm", "Alex/Kyle (14.51)"],
  ["Sept 8", "Classic - Devils Lake", "7am - 1pm", "Mark/Kevin (15.68)"]
];

const sundayTopFive = [
  ["Mark/Kevin", "102"],
  ["Jimmie", "87"],
  ["Marty/Terry", "81"],
  ["Ralph/Rodney", "66"],
  ["Aaron/Michael", "60"]
];

const sundayBigBass = [
  ["Jimmie (Wamplers)", "6.09"]
];

const fridays = [
  ["May 31", "Woodland", "Trent/Ava (13.53)"],
  ["June 7", "*Belleville", "Ralph/Robbie (10.98)"],
  ["June 14", "Chemung", "Ralph/Robbie (20.08)"],
  ["June 21", "Sand", "Jon/Aaron (13.41)"],
  ["June 28", "Lobdell", "Jon/Aaron (20.56)"],
  ["July 12", "Woodland", "Rodney/Mike (15.40)"],
  ["July 19", "Whitmore", "Jon/Aaron (19.18)"],
  ["July 26", "Lobdell", "Ralph/Robbie (10.82)"],
  ["Aug 2", "*Belleville", "Rodney/Mike (10.50)"],
  ["Aug 9", "Sand", "Ralph/Mike (19.14)"],
  ["Aug 16", "Whitmore", "Jon/Aaron (20.61)"],
  ["Aug 23", "Chemung", "Jon/Aaron (19.32)"],
  ["Sept 6", "Classic- Whitmore Lake", "Ralph/Robbie (18.05)"]
];

const fridayTopFive = [
  ["Ralph/Robbie", "109"],
  ["Jon/Aaron", "107"],
  ["Tim/Kyle", "76"],
  ["Rodney/Mike", "60"],
  ["Joe/Justin", "41"]
];

const fridayBigBass = [
  ["Ralph/Mike (Sand)", "5.84"]
];


export {
  sundays,
  sundayTopFive,
  sundayBigBass,
  fridays,
  fridayTopFive,
  fridayBigBass
}